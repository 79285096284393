<template>
  <section>
    <header>
      <ul class="container flex-between-center">
        <li>
          <h1><b class="color-primary">1200+</b> Projects  Built on BNB Chain Ecosystem</h1>
          <p>With low gas fee, huge user base and high growth rate, <br>BNB connects everything in one place.<br>From NFT to DeFi, BNB does it all.</p>
        </li>
        <li>
          <div class="flex-between move-bg">
            <img src="@/assets/img/banner/bg.png" height="316">
            <img src="@/assets/img/banner/bg.png" height="316">
          </div>
        </li>
      </ul>
    </header>
    <!--<main class="container home-header">
       <swiper
        class="top-banner swiper"
        :options="bannerOptions"
        v-if="banners && banners.length"
      >
        <swiper-slide v-for="(item, i) in banners" :key="i">
          <a
            class="swiper-img-box"
            :href="item.url ? item.url : 'javascript:;'"
            :target="item.url ? '_blank' : ''"
            rel="noopener noreferrer"
          >
            <img :src="v2_oss_link + 'banner/' + item.img" width="100%" />
          </a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper> 
    </main>-->
    <main class="bg-f5">
      <IndexInfo/>
    </main>
    <main class="container">
      <h1>{{ $t("Days7Ranking") }}</h1>
      <TokenList isHome="1" class="mt-16" />
      <p class="view-box">
        <router-link to="/rank" class="view">{{ $t("ViewAll") }}</router-link>
      </p>
    </main>
    <main class="bg-fa">
      <div class="container">
        <ul class="tabs pro-tabs">
          <li :class="{active:proTab==1}" @click="getProList(1,0)">
            Top DeFi Projects
          </li>
          <li :class="{active:proTab==0}" @click="getProList(0,12)">
            Top NFT
          </li>
          <li :class="{active:proTab==2}" @click="getProList(2,0)">
            Top GameFi
          </li>
        </ul>
        <ul class="flex-wrap-list pro-list">
          <li v-for="item in projectList" :key="item.id">
            <router-link :to="'/project/'+item.ID">
              <img v-if="item.img" :src=" project_oss_link + item.img" width="80" height="80"/>
              <img v-else src="@/assets/img/icon/default.png" width="80" height="80"/>
              <p class="mt-16">{{ item.title }}</p>
            </router-link>
          </li>
        </ul>
      </div>
    </main>
    <main
      class="container"
      v-if="
        (hottestDapp && hottestDapp.length) || (btokenApy && btokenApy.length)
      "
    >
      <h1>{{ $t("HighestAPYRecommend") }}</h1>
      <ul class="tabs">
        <li :class="{active:apyTab==1}" @click="apyTab=1">
            {{ $t("BestAPYDApp") }}
        </li>
        <li :class="{active:apyTab==2}" @click="apyTab=2">
            {{ $t("BTokensBestAPY") }}
        </li>
      </ul>
      <ul class="flex-wrap-list" v-if="apyTab==1&&hottestDapp && hottestDapp.length">
        <li
          class="col-4 piece-card"
          v-for="(item, index) in hottestDapp"
          :key="index"
        >
          <router-link :to="'/project/'+item.projectID">
            <div class="piece-top">
              <img :src="project_oss_link + item.img" width="40" @error="logoError"/>
              <span>{{ item.title }}</span>
            </div>
            <ul class="piece-info">
              <li
                class="flex-between-center"
                v-for="(y, i) in item.tokens"
                :key="i"
              >
                <span>{{ y.symbol }}</span>
                <span>{{ y.apy }}</span>
              </li>
            </ul>
          </router-link>
        </li>
      </ul>
      <ul class="flex-wrap-list" v-else-if="apyTab==2&&btokenApy && btokenApy.length">
        <li
          class="col-3 piece-card yellow-bottom-border"
          v-for="(item, index) in btokenApy"
          :key="index"
        >
          <p class="piece-top">{{ item.symbol }}</p>
          <ul class="piece-info">
            <li v-for="(y, i) in item.list" :key="i">
              <router-link :to="'/project/'+y.bscProjectID" class="flex-between-center">
                <div class="flex-align-center">
                  <img :src="project_oss_link + y.img" width="24" @error="logoError"/>
                  <div>
                    <p>{{ y.title }}</p>
                    <p class="text-sm color-grey">{{ y.symbols }}</p>
                  </div>
                </div>
                <div class="text-right">
                  <h5>{{ y.apy }}</h5>
                  <p class="text-sm color-grey">APY</p>
                </div>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
      <div v-else class="null">
        {{$t('NoInformation')}}
      </div>
    </main>
    <!-- <main
      class="container"
      v-if="(nftList && nftList.length) || (defiList && defiList.length)"
    >
      <h1>{{ $t("TopDAppRecommend") }}</h1>
      <h2 class="title flex-between-center mt-64" v-if="nftList && nftList.length">
        {{ $t("TopDAppNFT") }}
        <router-link to="/event" class="view">{{ $t("ViewMore") }}</router-link>
      </h2>
      <CateList :list="nftList" from="img/"/>
      <h2 class="title flex-between-center mt-64" v-if="defiList && defiList.length">
        {{ $t("TopDAppDeFi") }}
        <router-link to="/defi" class="view">{{ $t("ViewMore") }}</router-link>
      </h2>
      <CateList :list="defiList" from="defi/"/>
    </main> -->
    <!-- <main class="bg-fa" v-if="hottestCategories && hottestCategories.length">
      <div class="container">
        <h1 class="mb-40">{{ $t("HottestCategories") }}</h1>
        <ul class="fixed-width-list">
          <li v-for="item in hottestCategories" :key="item.ID">
            <router-link :to="'/projects?cateID=0&tagID=' + item.ID">
              <img
                :src="require('@/assets/img/categories/' + item.ID + '.png')"
                width="58"
              />
              <p class="mt-8">{{ item.title }}</p>
            </router-link>
          </li>
        </ul>
      </div>
    </main> -->
  </section>
</template>
<script>
import IndexInfo from "@/components/IndexInfo";
import TokenList from "@/components/TokenList";
import CateList from "@/components/CateList";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  components: { Swiper, SwiperSlide, IndexInfo, TokenList, CateList },
  data() {
    return {
      proTab:1,
      apyTab:1,
      bannerOptions: {
        grabCursor: true,
        loop: true,
        autoplay: {
          delay: 4000, //4秒切换一次
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      banners: null,
      hottestDapp: null,
      btokenApy: null,
      nftList: null,
      defiList: null,
      projectList:null,
      hottestCategories: [
        {
          ID: "1",
          title: "Exchange",
        },
        {
          ID: "12",
          title: "NFT",
        },
        {
          ID: "2",
          title: "Earn",
        },
        {
          ID: "3",
          title: "Lending",
        },
        {
          ID: "4",
          title: "Insurance",
        },
        {
          ID: "9",
          title: "Derivative",
        },
        {
          ID: "13",
          title: "Stablecoin",
        },
        {
          ID: "18",
          title: "Meme",
        },
        {
          ID: "0",
          title: this.$t("More"),
        },
      ],
      selectedTagID: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getProList(proTab,selectedTagID){
      this.$loading(1);
      this.proTab=proTab;
      this.selectedTagID=selectedTagID;
      this.axios.get(this.domain + 
          "getBscList?limit=8&flag=1&page=0&tagID="+this.selectedTagID+"&cateID=" + this.proTab
          ).then((res) => {
        this.projectList = res?.data?.data;
        this.$loading(0);
      });
    },
    getList() {
      this.$loading(1);
      let p1 = this.axios
        .get(
          this.domain +
            "getBscCategoryList?v=2.0&type=1&limit=8&page=0&tagIDs=0"
        )
        .then((res) => {
          this.nftList = res?.data?.data;
        });
      let p2 = this.axios
        .get(this.domain + "getDefiProjectList?limit=8&page=0&tagIDs=0")
        .then((res) => {
          this.defiList = res?.data?.data;
        });
      let p3 = this.axios.get(this.domain + "getProjectBanner").then((res) => {
        this.banners = res?.data?.data;
      });
      let p4 = this.axios.get(this.domain + "getHottestDappApy").then((res) => {
        this.hottestDapp = res?.data?.data;
      });
      let p5 = this.axios.get(this.domain + "getBtokenApy").then((res) => {
        this.btokenApy = res?.data?.data;
      });
      let p6 = this.getProList(this.proTab,this.selectedTagID);

      Promise.all([p1, p2, p3, p4, p5, p6])
        .then((res) => { 
          this.$loading(0);
        })
        .catch((err) => {
          console.log(err);
          this.$loading(0);
        });
    },
  },
};
</script>
<style scoped>
header{
  background: var(--darkColor);
  color: #fff;
  font-size: 20px;
  line-height: 1.4;
  padding: 0;
}
header .container{
  padding: 50px 0;
}
header li{
  width: 50%;
}
header h1{
  margin-bottom: 30px;
  font-size: 48px;
  line-height: 1.4;
}
header li:first-child{
  padding: 0 24px;
}
header li:last-child{
  overflow: hidden;
  position: relative;
}
header li:last-child:after{
  content:'';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background:  linear-gradient(to right, #12161c, rgba(18, 22, 28, 0) 30%, rgba(18, 22, 28, 0) ,#12161c)
}
header .move-bg{
  animation: moveLeft 20s linear infinite;
}
b{
  font-weight: normal;
}
h2.title {
  border: none;
}
.title .view {
  font-size: 14px;
  line-height: 20px;
}
.swiper-img-box{
  position:relative;
  width:100%;
  height:0;
  padding-top: 25%;
  display: block;
}
.swiper-img-box img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.tabs{
  margin:var(--px48) 0 var(--px_40);
}
.pro-tabs{
  margin:0 0 var(--px_40);
}
.tabs li{
  background: transparent;
  padding:0 0 14px;
  position: relative;
  margin:0 80px 0 0;
  font:400 24px/1.33 Rubik-Regular;
}
.tabs li.active:after{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 4px solid var(--mainColor);
}
.tabs li:last-child{
  margin-right: 0;
}
.tabs .active{
  font-weight: 500;
  font-family: Rubik-Medium;
}
.pro-list li{
  margin: 0 var(--px_24) 24px 0;
  width: 128px;
  text-align: center;
}
.pro-list li:last-child{
  margin-right: 0;
}
@keyframes moveLeft{
  from{
    transform: translateX(0);
  }
  to{
    transform: translateX(-1280px);
  }
}
@media(max-width:1024px){
  header .container{
    padding: 32px 0;
  }
  header h1{
    margin-bottom: 12px;
    font-size: 32px;
  }
}
@media(max-width:768px){
  .tabs{
    max-width: unset;
  }
  .tabs li{ 
    font-size: 16px;
    margin-right: 24px;
  }
}
@media (max-width:700px) {
  header .flex-between-center{
    flex-wrap: wrap;
  }
  header li{
    width: 100%;
    font-size: 14px;
  }
  header li:first-child{
    padding: 0 16px;
  }
  header li:last-child{
    margin-top: 60px;
  }
   .move-bg img{
    height: 158px;
  }
  @keyframes moveLeft{
    from{
      transform: translateX(0);
    }
    to{
      transform: translateX(-640px);
    }
  }
}
@media (max-width:500px) {
  .pro-list li{
    width: 32%;
    margin-right: 2%;
  }
  .pro-list li:nth-child(3n){
    margin-right: 0;
  }
}
</style>
