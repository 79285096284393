<template>
  <article class="index-info container">
    <a
      href="https://mathverse.xyz/"
      class="adv"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="@/assets/img/adv/logo.png"
        height="20"
        class="logo mb-16 mr-16"
      />
      <div class="flex-wrap">
        <p class="mb-16 mr-16">
          This First Digital Life NFT Collection
        </p>
        <img src="@/assets/img/adv/link2.png" height="30" class="link" />
      </div>
    </a>
    <h1>BNB Chain Today <span>(The latest network statistics)</span></h1>
    <ul class="flex-piece">
      <li class="card">
        <div class="tvl-part" v-if="klineDataList && klineDataList.length > 0">
          <h4>{{ $t("TVLDeFi") }}</h4>
          <h2>
            ${{ webUtil.addCommas(klineDataList[klineDataList.length - 1]) }}
            <span v-if="tvlPercentChange >= 0" class="color-up"
              >+{{ tvlPercentChange }}%</span
            >
            <span v-else class="color-down">{{ tvlPercentChange }}%</span>
          </h2>
          <KlinePart
            :timeList="klineTimeList"
            :dataList="klineDataList"
            v-if="klineDataList && klineDataList.length > 2"
          />
        </div>
      </li>
      <li class="card ml" v-if="userDataList && userDataList.length > 2">
        <div class="tvl-part">
          <h4>{{ $t("DailyActiveUsers") }}</h4>
          <h2>
            {{ webUtil.addCommas(userDataList[userDataList.length - 1]) }}
            <span v-if="userPercentChange >= 0" class="color-up"
              >+{{ userPercentChange }}%</span
            >
            <span v-else class="color-down">{{ userPercentChange }}%</span>
          </h2>
          <KlinePart
            :timeList="userTimeList"
            :dataList="userDataList"
            v-if="userDataList && userDataList.length > 2"
          />
        </div>
      </li>
    </ul>
    <ul class="flex-wrap-list">
      <li class="col-3">
        <div class="card">
          <div>
            <h6>{{ $t("AvgGasFee") }}</h6>
            <h3 v-if="avgGasFee > 0">${{ webUtil.addCommas(avgGasFee) }}</h3>
            <h3 v-else>--</h3>
          </div>
          <img src="@/assets/img/statistics/0.png" width="72" />
        </div>
      </li>
      <li class="col-3">
        <div class="card">
          <div>
            <h6>{{ $t("TotalAddress") }}</h6>
            <h3>
              {{ totalAddress > 0 ? webUtil.addCommas(totalAddress) : "--" }}
            </h3>
          </div>
          <img src="@/assets/img/statistics/1.png" width="72" />
        </div>
      </li>
      <li class="col-3">
        <div class="card">
          <div>
            <h6>{{ $t("Txn24H") }}</h6>
            <h3>{{ txn24H > 0 ? webUtil.addCommas(txn24H) : "--" }}</h3>
          </div>
          <img src="@/assets/img/statistics/2.png" width="72" />
        </div>
      </li>
    </ul>
  </article>
</template>
<script>
import KlinePart from "./KlinePart.vue";
export default {
  components: { KlinePart },
  data() {
    return {
      klineTimeList: [],
      klineDataList: [],
      userTimeList: null,
      userDataList: null,
      avgGasFee: "",
      totalAddress: "",
      txn24H: "",
      tvlPercentChange: 0,
      userPercentChange: 0,
    };
  },
  created() {
    this.getKlineData();
  },
  methods: {
    getKlineData() {
      this.axios
        .get(this.domain + "getBscProjectTvl")
        .then((res) => {
          if (res.data.success) {
            this.avgGasFee = res.data.data.avgGasFee;
            this.totalAddress = res.data.data.totalAddress;
            this.txn24H = res.data.data.txn24H;
            this.tvlPercentChange = (
              res.data.data.tvlPercentChange * 100
            ).toFixed(2);
            this.userPercentChange = (
              res.data.data.activeUserPercentChange * 100
            ).toFixed(2);
            let dataList = res.data.data.list;
            let userList = res.data.data.userList;
            if (dataList) {
              this.klineTimeList = dataList.map((v) => v.showDate.slice(5, 11));
              this.klineDataList = dataList.map((v) => v.tvl);
            } else {
              this.klineTimeList = null;
              this.klineDataList = null;
            }
            if (userList) {
              this.userTimeList = userList.map((v) => v.showDate.slice(5, 11));
              this.userDataList = userList.map((v) => v.activeUser);
            } else {
              this.userTimeList = null;
              this.userDataList = null;
            }
          } else {
            this.klineDataList = null;
            this.userDataList = null;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.container {
  padding-top: 40px;
}
.adv {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background: url(../assets/img/adv/bg.png) no-repeat center/cover;
  color: #fff;
  padding: 24px 60px 8px 24px;
  border-radius: 4px;
  font-size: 24px;
  line-height: 1.25;
  position: relative;
  margin-bottom: 40px;
}
.adv .logo {
  margin-top: 4px;
}
.adv .link {
  position: absolute;
  right: 16px;
  top: 16px;
}
.adv .mb-16 {
  margin-bottom: 16px;
}
.adv .flex-wrap {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.mr-16 {
  margin-right: 16px;
}
.card {
  background: #fff;
  border-radius: 8px;
  margin-top: var(--px_24);
}
.flex-wrap-list .card {
  padding: 14px 24px 14px var(--px_40);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-piece .card {
  flex: 1;
  padding: var(--px_32) var(--px_40);
}
.flex-piece .ml {
  margin-left: 2%;
}
h2 {
  font: bold 24px/1.33 Rubik-Medium;
  font-variant-east-asian: traditional;
  margin-bottom: 8px;
}
h2 span {
  font-size: 12px;
}
h4 {
  font-family: Rubik-Regular;
  font-weight: normal;
}
@media (max-width: 768px) {
  .flex-piece .card {
    width: 100%;
    margin-left: 0;
  }
  .col-3 {
    margin: 0;
  }
  .adv {
    display: block;
    font-size: 18px;
    line-height: 22px;
    padding: 20px 60px 8px 16px;
    background-image: url(../assets/img/adv/bg2.png);
  }
  .adv .logo {
    height: 16px;
  }
  /* .adv .link {
    height: 24px;
  } */
}
</style>
