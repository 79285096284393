import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import( '../views/Projects.vue' ),
    props:   route  => ({
      cateID: route.query.cateID,
      tagID: route.query.tagID,
    })
  },
  {
    path: '/rank',
    name: 'Ranking',
    component: () => import('../views/Rank.vue')
  },
  {
    path: '/btoken',
    name: 'BToken',
    component: () => import('../views/BToken.vue')
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: () => import( '../views/Project.vue' ),
    props:true
  },
  {
    path: '/submit',
    name: 'Submit',
    component: () => import('../views/Submit.vue'),
    meta: {
      noSubmit:true,
    }
  },
  {
    path: '/rpcserver',
    name: 'RPCServer',
    component: () => import('../views/RPCServer.vue')
  },
  {
    path: '/gasprice',
    name: 'GasPrice',
    component: () => import('../views/GasPrice.vue')
  },
  {
    path: '/event',
    name: 'Event',
    component: () => import( '../views/Event/Index.vue' ),
    meta: {
      noSubmit:true,
    }
  },
  {
    path: '/event/events',
    name: 'Events',
    component: () => import( '../views/Event/Events.vue' ),
    meta: {
      noSubmit:true,
    }
  },
  {
    path: '/event/projects',
    name: 'EventProjects',
    component: () => import( '../views/Event/Projects.vue' ),
    meta: {
      noSubmit:true,
    }
  },
  {
    path: '/eventsubmit',
    name: 'EventSubmit',
    component: () => import( '../views/Event/Submit.vue' ),
    meta: {
      noSubmit:true,
    },
  },
  {
    path: '/projectsubmit',
    name: 'ProjectSubmit',
    component: () => import( '../views/Event/ProjectSubmit.vue' ),
    meta: {
      noSubmit:true,
    },
  },
  {
    path: '/defi',
    name: 'Defi',
    component: () => import( '../views/Defi/Index.vue' ),
    meta: {
      noSubmit:true,
    }
  },
  {
    path: '/defi/events',
    name: 'DefiEvents',
    component: () => import( '../views/Defi/Events.vue' ),
    meta: {
      noSubmit:true,
    }
  },
  {
    path: '/defi/projects',
    name: 'DefiProjects',
    component: () => import( '../views/Defi/Projects.vue' ),
    meta: {
      noSubmit:true,
    }
  },
  {
    path: '/defi/eventsubmit',
    name: 'DefiSubmit',
    component: () => import( '../views/Defi/Submit.vue' ),
    meta: {
      noSubmit:true,
    },
  },
  {
    path: '/defi/projectsubmit',
    name: 'DefiProjectSubmit',
    component: () => import( '../views/Defi/ProjectSubmit.vue' ),
    meta: {
      noSubmit:true,
    },
  },
  {
    path: '/collections',
    name: 'Collections',
    component: () => import( '../views/Collections.vue' ),
  },
  {
    path: '/membership',
    name: 'Membership',
    component: () => import( '../views/Membership.vue' ),
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
