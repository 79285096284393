<template>
  <article>
    <h2 class="title" v-if="!isHome">{{$t('Rank')}}</h2>
    <div class="flex-between-center" v-if="!isHome">
      <ul class="tabs" v-if="tagList && tagList.length">
        <li
          v-for="item in tagList"
          :key="item.ID"
          :class="{ active: selectedTagID == item.ID }"
          @click="getListByTag(item.ID)"
        >
          {{ item.title }}
        </li>
      </ul>
      <p class="sort-type">
        <i
          :class="{ active: selectedTimeType == 0 }"
          @click="selectedTimeType = 0"
          >24H</i
        >
        /
        <i
          :class="{ active: selectedTimeType == 1 }"
          @click="selectedTimeType = 1"
          >7D</i
        >
      </p>
    </div>
    <main class="table-responsive">
      <table v-if="rankList && rankList.length > 0">
        <thead>
          <tr>
            <th>#</th>
            <th>{{$t('Project')}}</th>
            <th
              v-for="(item,i) in sortList" :key="i"
              :class="['pointer', { active: sortByWho == item.name }]"
              @click="sortRank(item)"
            >
              <p v-if="item.order" class="flex-align-center">
                <i v-html="$t(item.name)"></i>
                <img
                  width="12"
                  :src="item.order == 'desc'? downImg : upImg"
                />
              </p>
              <p v-else>{{$t(item.name)}}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, i) in rankList"
            :key="item.ID"
            @click="goProjectDetail(item)"
          >
            <td>{{ 10 * (page - 1) + (i + 1) }}</td>
            <td>
              <div class="flex-align-center">
                <img
                  :src="
                    project_oss_link +
                    item.img
                  "
                  width="32"
                  @error="logoError"
                />
                <div class="td-max-width">
                  <p class="text-ellipsis">{{ item.title }}</p>
                  <h6 class="color-grey text-ellipsis">
                    <i
                      v-for="(tag, index) in item.tags"
                      :key="index"
                      class="mr-6"
                      >{{ tag }}</i
                    >
                  </h6>
                </div>
              </div>
            </td>
            <td>{{ item.tvl > 0 ? item.tvlShort : "--" }}</td>
            <template v-if="item.tvlChange || item.tvlWeekChange ">
              <td v-if="selectedTimeType == 0" :class="[item.tvlChange>=0?'color-up':'color-down']">{{(webUtil.forPct(item.tvlChange)+'%')}}</td>
              <td v-else :class="[item.tvlWeekChange>=0?'color-up':'color-down']">{{(webUtil.forPct(item.tvlWeekChange)+'%')}}</td>
            </template>
            <td v-else>--</td>
            <td>{{ item.address > 0 ? item.addressShort : "--" }}</td>
            <template v-if="item.addressChange || item.addressWeekChange ">
              <td v-if="selectedTimeType == 0" :class="[item.addressChange>=0?'color-up':'color-down']">{{webUtil.forPct(item.addressChange) }}%</td>
              <td v-else :class="[item.addressWeekChange>=0?'color-up':'color-down']">{{webUtil.forPct(item.addressWeekChange) }}%</td>
            </template>
            <td v-else>--</td>
            <td>{{ item.txn > 0 ? item.txnShort : "--" }}</td>
            <template v-if="item.txnChange || item.txnWeekChange ">
              <td v-if="selectedTimeType == 0" :class="[item.txnChange>=0?'color-up':'color-down']">{{webUtil.forPct(item.txnChange) }}%</td>
              <td v-else :class="[item.txnWeekChange>=0?'color-up':'color-down']">{{webUtil.forPct(item.txnWeekChange) }}%</td>
            </template>
            <td v-else>--</td>
            <td>{{ item.token ? item.token.symbol : "--" }}</td>
            <td v-if="item.token && item.price > 0">${{ webUtil.addCommas(item.price) }}</td>
            <td v-else>--</td>
            <template v-if="item.percentChange || item.percentChangeWeek ">
              <td v-if="selectedTimeType == 0" :class="[item.percentChange>=0?'color-up':'color-down']">{{item.percentChange }}%</td>
              <td v-else :class="[item.percentChangeWeek>=0?'color-up':'color-down']">{{item.percentChangeWeek }}%</td>
            </template>
            <td v-else>--</td>
            <td>{{item.like?item.like:'--'}}</td>
          </tr>
        </tbody>
      </table>
      <div class="null" v-else>{{$t('NoInformation')}}</div>
    </main>
    <Pagination
      :total="total"
      :page="page"
      :limit="limit"
      @emitPage="getRank"
      v-if="!isHome"
    />
  </article>
</template>
<script>
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },
  props: ["isHome"],
  data() {
    return {
      // sort表示要排序的字段，支持'tvl', 'address', 'txn', 'price',
      // order=asc升序 order=desc降序
      sortList:[
        {
          name: "tvl",
          order: "desc",
        },
        {
          name: "tvlChange",
          order: "desc",
        },
        {
          name: "address",
          order: "desc",
        },
        {
          name: "addressChange",
          order: "desc",
        },
        {
          name: "txn",
          order: "desc",
        },
        {
          name: "txnChange",
          order: "desc",
        },
        {
          name: "Token",
        },
        {
          name: "price",
          order: "desc",
        },
        {
          name: "percentChange",
          order: "desc",
        },
        {
          name: "like",
          order: "desc",
        },
      ],
      sortByWho: "tvl",
      sortOrder: "desc",
      selectedTimeType:1,
      total: 0,
      limit: 10, //每页几条数据
      page: 1,
      rankList: null,
      tagList:null,
      selectedTagID:0,
    };
  },
  created() {
    // this.sortByWho=this.isHome?'tvlChange':'tvl';
    this.getRankTags();
  },
  methods: {
    goProjectDetail(item) {
      if (item.ID) {
        window.open("/#/project/" + item.ID,'_blank');
      }
    },
    sortRank(item) {
      if (item) {
        if (this.sortByWho == item.name) {
          item.order = item.order == "desc" ? "asc" : "desc";
        }
        this.sortByWho = item.name;
        this.sortOrder = item.order;
      }
      this.getRank();
    },
    getRank(page = 1) {
      this.page = page;
      this.$loading(1);
      let sort = this.sortByWho;
      // order=asc升序 order=desc降序
      if(this.sortByWho.includes('Change')){
        if(this.selectedTimeType==1){
          sort = this.sortByWho.replace('Change','WeekChange');
        }else{
          sort = this.sortByWho.replace('Change','24HChange');
        }
      }
      this.axios
        .get(
          this.domain +
            "getBscProjectTokensRank?v=2.0&tagID="+this.selectedTagID+"&sort=" +
            sort +
            "&order=" +
            this.sortOrder +
            "&limit=" +
            this.limit +
            "&page=" +
            page
        )
        .then((res) => {
          this.$loading(0);
          if (res.data.success) {
            this.rankList = res.data.data.list;
            this.total = res.data.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$loading(0);
        });
    },
    getListByTag(ID){
      this.selectedTagID = ID;
      this.getRank();
    },
    getRankTags(){
      this.$loading(1);
      this.tagList = [{
        ID:0,
        title:this.$t('All')
      }]
      this.axios.get(this.domain+'getRankTags').then(res=>{
        if(res.data.success){
          let tagList = res.data.data;
          this.tagList = [...this.tagList,...tagList];
        }
        this.getRank();
      }).catch(()=>{
        this.getRank();
      })
    }
  },
};
</script>
<style scoped>
table td:nth-child(2) {
  min-width: 160px;
}

td:nth-child(2) .td-max-width {
  width: calc(100% - 48px);
}

th:nth-child(n+3),
td:nth-child(n+3) {
  text-align: right;
}
th .flex-align-center {
  justify-content: flex-end;
}
th:last-child,td:last-child{
  min-width: 40px;
}
</style>
