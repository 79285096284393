import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../assets/js/i18n'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: i18n.locale,
    // account: sessionStorage.getItem('account')?sessionStorage.getItem('account'):'', //当前账户地址
    account: '', //当前账户地址
  },
  getters: {
    lang(state) {
      return state.lang
    },
    account(state) {
      return state.account
    },
  },
  mutations: {
    setLang(state, val) {
      i18n.setUserLanguage(val)
      state.lang = val;
    },
    setAccount(state, val) {
      state.account = val;
      // if ( val ) {
      //   sessionStorage.setItem('account', val)
      // } else {
      //   sessionStorage.removeItem('account');
      // }
    },
    logout(state) {
      state.account = null;
      // 清除sessionStorage
      // sessionStorage.removeItem('account');
    },
  },
  actions: {
    setLang(context, val) {
      context.commit('setLang', val)
    },
    setAccount(context, val) {
      context.commit('setAccount', val)
    },
    logout(context, val) {
      context.commit('logout', val)
    },
  },
})
