<template>
  <div id="app">
    <Nav :hasSubmit="hasSubmit"/>
    <router-view class="main-content"/>
    <Foot/>
  </div>
</template>
<script>
import Nav from "./components/Nav";
import Foot from "./components/Foot";
export default {
  components: { Nav,Foot },
  data(){
    return {
      hasSubmit:true,
    }
  },
  created(){
    this.$router.afterEach(item => {
      // 百度统计
      this.$ba.trackPageview(item.path);
      if(item.meta.noSubmit){
        this.hasSubmit = false;
      }else{
        this.hasSubmit = true;
      }
    })
  },
};
</script>
<style scoped>
.main-content{
  min-height: calc(100vh - 144px);
}
@media(max-width:940px) {
  .main-content{
    min-height: calc(100vh - 128px);
  }
}
@media(max-width:768px){
  .main-content{
    min-height: calc(100vh - 160px);
  }
}
</style>
