import LoginModalComponent from './LoginModal.vue'

const LoginModal = {};

LoginModal.install = function (Vue) {
  const LoginModalConstructor = Vue.extend(LoginModalComponent)
  const instance = new LoginModalConstructor();

  instance.$mount(document.createElement('div'))
  document.body.appendChild(instance.$el)

  Vue.prototype.$loginModal = ( bool, el ) => {
    return new Promise( resolve => {
      instance.loginModalShow = bool;
      instance.$loading = ( info ) => {
        return el.$loading(info)
      };
      instance.$t = ( info ) => {
        return el.$t(info)
      };
      instance.setAccount = ( info ) => {
        resolve(info);
        return el.setAccount(info)
      };
    })
  }
}

export default LoginModal
