<template>
<article
    class="login-container"
    v-if="loginModalShow"
    @click.self="loginModalShow = false"
  >
    <div class="login-info">
      <img
        src="@/assets/img/icon/modal-close.png"
        width="24"
        class="modal-close"
        @click="loginModalShow = false"
      />
      <h2>{{$t('Connect')}}</h2>
      <p>{{$t('SelectLoginAccount')}}</p>
      <a v-for="(item,i) in extensions" :key="i"
        class="border-btn"
        @click="getAccount(item)"
        ><img :src="require('@/assets/img/login/'+item.icon)" width="24" />
        <span>{{item.title}}</span></a
      >
    </div>
  </article>
</template>
<script>
export default {
  data(){
    return{
      accounts:[],
      loginModalShow: false, //登录弹窗是否显示
      extensions:[{
        title:"Metamask",
        icon:"metamask.png",
      },{
        title:"Binance Extension",
        icon:"binance.png",
      },{
        title:"MathWallet Extension",
        icon:"mathwallet.png",
      },{
        title:"Trust Wallet",
        icon:"trustwallet.png",
      },],
      selectedExtension:'MathWallet Extension',
    }
  },
  methods:{
    async getAccount(item) {
      this.selectedExtension = item.title;
      this.loginModalShow = false;
      this.$loading(1);
      let tries=0;
      let timer = setInterval(async()=>{
        tries++;
        if(window.ethereum){
          clearInterval(timer);

          ethereum.request({ method: 'eth_requestAccounts' }).then((accounts)=>{
            if(accounts&&accounts.length){
              this.setAccount(accounts[0]);
            }
            this.$loading(0);
          }).catch(err=>{
            this.$loading(0);
            this.$toast(err,3000)
          })
        }
        if(tries > 10){
          clearInterval(timer);
          this.$toast(this.$t("NoExtension").replace('w%',this.selectedExtension));
          this.$loading(0);
        }
      }, 100);
    },
  }
}
</script>
<style scoped>
.login-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-container .modal-close {
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 10000;
  cursor: pointer;
}
.login-info {
  padding: 40px;
  background: #fff;
  width: 100%;
  max-width: 480px;
  margin: auto;
  position: relative;
  border-radius: 20px;
}

.login-container h2 {
  font: bold 24px/1.33 Rubik-Medium;
  margin-bottom: 8px;
  color: #000;
}
.login-info p {
  color: #000;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 32px;
}
.login-info .border-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  margin-top: 8px;
  line-height: 24px;
  padding: 12px 16px;
  border: var(--border);
  cursor: pointer;
}
.border-btn img {
  margin-right: 16px;
}
.login-info .border-btn:hover{
  background:rgba(240, 185, 11, 0.1);
}
@media(max-width:768px){
  .login-container {
    left: 0;
    padding: 16px;
  }
}
</style>