<template>
<nav class="flex-between-center" @click="dropHide">
  <router-link to="/"><img src="@/assets/img/icon/logo.png" height="36"></router-link>
  <div :class="['nav-tab',{mobileShow:navMobileShow}]">
    <img v-show="navMobileShow||miniShow" @click="navMobileShow=false;miniShow=false" class="nav-close" src="@/assets/img/icon/close_white.png" width="30">
    <ul class="flex-align-center">
      <li>
        <router-link to="/">{{$t('Home')}}</router-link>
      </li>
      <li>
        <router-link to="/projects">{{$t('Project')}}</router-link>
      </li>
      <!-- <li>
        <router-link to="/event">NFT</router-link>
      </li>
      <li>
        <router-link to="/defi">DeFi</router-link>
      </li> -->
      <li v-for="(item,i) in tabs" :key="i">
        <router-link :to="'/'+item.toLowerCase()">{{$t(item)}}</router-link>
      </li>
      <li v-if="$route.path!='/projects'">
        <input type="text" placeholder="Search" @keydown.enter="$router.push('/projects')">
      </li>
    </ul>
    <ul :class="['flex-align-center nav-right-part',{miniShow:miniShow}]">
      <li v-if="hasSubmit">
        <router-link to="/submit">{{$t('Submit')}}</router-link>
      </li>
      <!-- <li class="dropdown lang">
        <p class="pointer" @click.stop="langDropShow=!langDropShow;dropShow=false">{{$t('lang')}} <img src="@/assets/img/icon/downward_grey.png" width="14"></p>
        <ul class="dropdown-content" v-show="langDropShow">
          <li class="pointer" v-for="(item,i) in langType" :key="i" @click="toggleLang(item.type)">{{item.name}}</li>
        </ul>
      </li> -->
      <li class="dropdown login">
        <a v-if="!account||account=='undefined'" class="border-btn" @click="login">{{$t("Connect")}}</a>
        <a
          v-else
          class="border-btn active"
          @click.stop="
            dropShow = !dropShow;
            langDropShow=false
          "
        >
          <span>{{ webUtil.formatStrByDot(account) }}</span>
          <img src="@/assets/img/icon/downward_grey.png" width="16" />
        </a>
        <div class="dropdown-content" v-show="dropShow">
          <router-link to="/collections">{{$t('MyFavorites')}}</router-link>
          <router-link to="/membership">{{$t('Membership')}}</router-link>
          <a @click="logoutAction">{{ $t("Disconnect") }}</a>
        </div>
      </li>
    </ul>
  </div>
  <img class="menu pointer" @click.stop="navMobileShow=true" src="@/assets/img/icon/menu.png" width="32">
  <img class="min-menu pointer" @click.stop="miniShow=true" src="@/assets/img/icon/menu.png" width="32">
</nav>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  props:['hasSubmit'],
  data(){
    return {
      langDropShow:false,
      dropShow:false,
      navMobileShow:false,
      miniShow:false,
      tabs: ["Rank","RPCServer","GasPrice"],
      // tabs: ["Rank" ,"BToken" ,"RPCServer" ,"GasPrice","Membership"],
      langType:[
        {
          type:'en',
          name:'English'
        },
        {
          type:'cn',
          name:'中文'
        },
      ],
    }
  },
  mounted(){
    document.documentElement.onclick=()=>{
      this.dropHide();
    }
  },
  methods:{
    ...mapActions(['setLang']),
    logoutAction(){
      this.logout();
      this.dropHide();
    },
    dropHide(){
      this.langDropShow = false;
      this.navMobileShow = false;
      this.miniShow = false;
      this.dropShow = false;
    },
    goRoute(item){
      this.dropHide();
      if(item){
        item = item.toLowerCase();
      }
      this.$router.push('/'+item);
    },
    toggleLang(lang){
      this.dropHide();
      //切换语言
      this.setLang(lang);
    }
  }
}
</script>
<style scoped>
nav{
  background: var(--darkColor);
  padding: 8px 24px;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}
nav a{
  color: #fff;
  cursor: pointer;
}
.nav-tab{
  margin-left: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}
.nav-tab>ul>li>a,.dropdown>p{
  padding: 12px;
  display: block;
}
.dropdown>p{
  display: inline-block;
}
.nav-tab>ul>li>.border-btn{
  padding: 4px 16px;
  font-size: 16px;
  line-height: 24px;
  margin-left: 12px;
  cursor: pointer;
  display: inline-block;
}
.nav-tab>ul>li>.active {
  padding-left: 36px;
  position: relative;
}
.nav-tab>ul>li>.active:after {
  content: "";
  position: absolute;
  left: 16px;
  top: 11px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--mainColor);
  box-shadow: 0 0 8px 0 var(--mainColor);
}
.login .dropdown-content{
  right: 0;
  left: 12px;
  top: 42px;
  text-align: center;
  max-width: 166px;
}
.nav-close{
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 10001;
}
.mobileShow,.miniShow{
  position: fixed;
  background: var(--darkColor);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  z-index: 10000;
  padding: 60px 16px 40px;
  overflow-y: auto;
}
.mobileShow>ul{
  display: block;
  text-align: center;
}
nav input{
  background:rgba(255, 255, 255, 0.16) url(../assets/img/icon/search-white.png)no-repeat 16px center/16px;
  padding: 4px 16px 4px 48px;
  border-radius: 16px;
  border: none;
  margin-left: 12px;
  color: #fff;
  display: inline-block;
  width: auto;
}
@media(min-width: 1251px){
  .min-menu,.nav-close{
    display: none;
  }
}
@media(min-width: 1025px){
  .menu{
    display: none;
  }
}
@media(max-width: 1250px){
  .nav-right-part{
    display: none;
  }
  .miniShow{
    display: block;
    text-align: center;
  }
  .login{
    padding: 12px 0;
  }
  .nav-tab>ul>li>.border-btn{
    margin-left: 0;
  }
  .dropdown-content,.login .dropdown-content{
    left: 50%;
    transform: translateX(-50%);
  }
  .login .dropdown-content{
    top: 56px;
  }
}
@media(max-width:1024px){
  nav input{
    display: none;
  }
  nav{
    padding: 6px 16px 6px 0;
  }
  .nav-tab,.min-menu{
    display: none;
  }
  .mobileShow,.nav-right-part{
    display: block;
  }
  .mobileShow>ul{
    display: block;
    text-align: center;
  }
  .nav-tab .dropdown-content{
    left: 50%;
    transform: translateX(-50%);
  }

}
</style>