<template>
<ul class="flex-wrap-list" v-if="list&&list.length">
  <li class="col-4" v-for="item in list" :key="item.ID">
    <a :href="item.url?item.url:'javascript:;'" :target="item.url?'_blank':''" rel="noopener noreferrer">
      <img v-lazy="event_oss_link+from+item.img" width="100%">
      <p class="mt-16 text-ellipsis2">{{item.title}}</p>
      <p class="text-sm text-ellipsis2 color-grey mt-4">{{item.description}}</p>
    </a>
  </li>
</ul>
</template>
<script>
export default {
  props:['from','list'],
}
</script>