import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from './store'
import axios from "axios";
import i18n from './assets/js/i18n'
import lodash from 'lodash'
import Toast from "./components/Toast";
import Loading from "./components/Loading";
import VueClipboard from "vue-clipboard2";
import ba from "vue-ba";
import webUtil from "./assets/js/util";
import Sparklines from "vue-sparklines"
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueLazyload from 'vue-lazyload'
import LoginModal from './components/LoginModal'
import user from '@/assets/js/user'
import "./assets/css/index.css";

Vue.config.productionTip = false;
Vue.use(VueLazyload, {
  preLoad: 1,
  error: require('./assets/img/event/icon/default.png'),
  loading: require('./assets/img/event/icon/default.png'),
  attempt: 1
})
Vue.use( VueAwesomeSwiper )
Vue.use(VueClipboard);
Vue.use(Toast);
Vue.use(Loading);
Vue.use( Sparklines );
Vue.use( LoginModal );


// 百度统计
Vue.use(ba, "4d699ceaca219538bf802bf2da302d1f");
Vue.use(ba, { siteId: "4d699ceaca219538bf802bf2da302d1f" });

import { mapGetters, mapActions } from "vuex";
Vue.mixin({
  data() {
    return {
      lodash:lodash,
      mainColor:'#f0b90b',
      mainDarkColor:'#12161c',
      webUtil: webUtil,
      axios: axios,
      user: user,
      signMessageStr:"bscproject",
      downImg: require('@/assets/img/icon/down.png'),
      upImg: require( '@/assets/img/icon/up.png' ),
      project_oss_link:"https://oss-us-cdn.maiziqianbao.net/bsc_project/",
      event_oss_link:"https://oss-us-cdn.maiziqianbao.net/bsc_event/",
      v2_oss_link:"https://oss-us-cdn.maiziqianbao.net/bsc_project_v2/",
      marketDomain: process.env.NODE_ENV === "production" ? "https://market.maiziqianbao.net/":"/market/",
      domain: process.env.NODE_ENV === "production" ? "https://api.staked.xyz/apiBscProject/" : "/api/",
      bscprojectDomain: process.env.NODE_ENV === "production" ? "https://api.bscproject.org/api/" : "/bscproject/",
      contact: [
        "website",
        "email",
        "twitter",
        "telegram",
        "github",
        "job",
        "coinmarketcap",
        "discord"
      ],
      eventTags: [
        { ID: "0", title: "AMA"},
        { ID: "1", title: "Meetup" },
        { ID: "2", title: "Activity" },
        { ID: "3", title: "Hackthon" },
      ],
      defiEventTags: [
        { ID: "1", title: "AMA"},
        { ID: "2", title: "Meetup" },
        { ID: "3", title: "Activity" },
        { ID: "4", title: "Hackthon" },
        { ID: "5", title: "Airdrop" },
      ]
    };
  },
  computed: {
    ...mapGetters({
      lang: 'getLang',
      account: 'account',
    }),
  },
  methods: {
    ...mapActions( ["setAccount",'logout'] ),
    async login() {
      await this.$loginModal( true, this )
    },
    logoError(e){
      let img = e.srcElement;
      img.src = require('@/assets/img/icon/default.png');
      img.style.boxShadow = 'none';
      img.onerror = null; //防止闪图
    },
    // 复制
    copyAction(val = window.location.href) {
      this.$copyText(val).then(
        () => {
          this.$toast("Successfully copied");
        },
        () => {
          this.$toast("Copy failed");
        }
      );
    },
    openLink(link, type) {
      if (type == "email") {
        window.location.href = "mailto:" + link;
      } else {
        window.open(link, "_blank");
      }
    },
    async sign() {
      if ( !this.account ) {
        await this.login();
      }
      this.$loading( 1 );

      let signRes = await user.signMessage( this.signMessageStr ).catch( (err) => {
        this.$loading( 0 );
        this.catchERR( err );
      })

      return signRes
    },
    catchERR( err ) {
      if (err) {
        this.$toast(err)
      } else {
        this.logout();
        this.$toast(this.$t('SelectLoginAccount'))
      }
    }
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
